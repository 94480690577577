import Model from '@/application/models/model.js';

const amountOfColumns = 3;

export default class Dashboard extends Model {
  id = null;
  name = '';
  content = [...Array(amountOfColumns).keys()].map(() => new Column());

  mapForRequest() {
    return {
      name: this.name,
      content: this.content.map(column => column.mapForRequest()),
    };
  }

  mapResponse({
    content,
    ...data
  }) {
    this.content = this.content.map(
      (column, colIndex) => {
        if (!content[colIndex]) return column;
        return column.mapResponse(content?.[colIndex]);
      },
    );
    return super.mapResponse(data);
  }
}

export class Column extends Model {
  categories = [];

  mapForRequest() {
    return this.categories.length === 0
      ? null
      : { categories: this.categories.map(category => category?.mapForRequest() || null) };
  }

  mapResponse(data) {
    data.categories = data.categories.map(category => new Category().mapResponse(category));
    return super.mapResponse(data);
  }
}

export class Category extends Model {
  title = '';
  links = [];

  mapForRequest() {
    return {
      title: this.title,
      links: this.links.map(link => link.mapForRequest()),
    };
  }

  mapResponse(data) {
    if ('links' in data) data.links = data.links.map(link => new Link().mapResponse(link));
    return super.mapResponse(data);
  }
}

export class Link extends Model {
  name = '';
  urls = [];
  url = '';
  openInNewWindow = false;
  type = '';
  identifier = null;
  mapForRequest() {
    return {
      name: this.name,
      urls: this.urls,
      openInNewWindow: this.openInNewWindow,
      type: this.type,
      identifier: this.identifier,
    };
  }
}
