import { destroy, get, post, put } from '@/api/implementation/app';
import { getPaginated } from '@/api/implementation/app/wrapper.js';

export const autocomplete = (...args) => getPaginated(`admin/dashboard`, ...args);

export const index = (...args) => getPaginated('admin/dashboard', ...args);

export const show = (dashboardId) => get(`dashboard/${dashboardId}`);

export const create = (dashboard) => post('admin/dashboard', dashboard);

export const update = (dashboard) => put(`admin/dashboard/${dashboard.id}`, dashboard);

export const remove = (dashboardId) => destroy(`admin/dashboard/${dashboardId}`)
