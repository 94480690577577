<template>
  <v-container>
    <v-row v-if="$route.name === 'oauth.unauthorized'" no-gutters>
      <v-alert outlined type="info">
        {{ $t('authorisation.unauthorizedMicroservice') }}
        <v-btn :to="{name: 'home'}" class="ml-6" color="info" exact outlined>{{ $t('actions.close') }}</v-btn>
      </v-alert>
    </v-row>
    <CustomizedStartPage v-if="dashboardId" :dashboard-id="dashboardId"/>
    <MicroservicesCards v-else/>
  </v-container>
</template>

<script lang="js">
import CustomizedStartPage from '@/components/dashboard/CustomizedPage.vue';
import MicroservicesCards from '@/components/dashboard/MicroservicesCards.vue';
import eventBus from '@/eventBus.js';
import { GET_PROFILE } from '@/store/modules/profile/actionTypes.js';
import { mapGetters } from 'vuex';

export default {
  name: 'Dashboard',
  components: {
    CustomizedStartPage,
    MicroservicesCards,
  },
  computed: {
    ...mapGetters('profile', { profile: GET_PROFILE }),
    dashboardId() {
      return parseInt(this.$route.params.dashboardId) || this.profile.dashboardId;
    },
    isHomepageDashboard() {
      return !this.$route.params.dashboardId;
    },
  },
  watch: {
    dashboardId: {
      immediate: true,
      handler() {
        if (!this.isHomepageDashboard) {
          return eventBus.$emit('setBreadcrumbs', [
            {
              exact: true,
              to: { name: 'home' },
              text: this.$t('global.dashboard'),
            },
            {
              exact: true,
              to: {
                name: 'dashboard.show',
                params: { dashboardId: this.dashboardId },
              },
              text: this.$t('global.dashboard'),
            }]);
        }

        eventBus.$emit('setBreadcrumbs', [{
          exact: true,
          to: { name: 'home' },
          text: this.$t('global.dashboard'),
        }]);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-skeleton-loader__heading {
    margin-left: 4px;
  }
}
</style>
