<template>
  <v-row align="start" justify="start" v-if="isSuccessful">
    <v-col xs="12" sm="6" md="3" v-for="microservice in microservices" :key="microservice.id">
      <v-card outlined tile :href="microservice.url">
        <div class="ma-3">
          <VImg :alt="microservice.name" :src="microservice.image"/>
        </div>
        <v-card-subtitle>
          <span class="text--secondary">{{ microservice.name }}</span>
        </v-card-subtitle>
      </v-card>
    </v-col>
  </v-row>
  <v-row v-else>
    <v-col xs="12" sm="6" md="3" v-for="n in 4" :key="n">
      <VSkeletonLoader type="image" outlined tile height="108"/>
      <VSkeletonLoader type="card-heading"/>
    </v-col>
  </v-row>
</template>

<script lang="js">
import { mapActions, mapGetters } from 'vuex';
import eventBus from '@/eventBus.js';
import { FETCH_MICROSERVICES, GET_MICROSERVICES, } from '@/store/modules/microservices/actionTypes.js';

export default {
  name: 'MicroservicesCards',
  computed: {
    ...mapGetters('microservices', {microservices: GET_MICROSERVICES, isSuccessful: 'isSuccessful'})
  },
  async created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'home' },
        text: this.$t('global.dashboard'),
      },
    ]);
    await this.fetchMicroservices();
  },
  methods: {
    ...mapActions('microservices', { fetchMicroservices: FETCH_MICROSERVICES }),
  },
};
</script>
