<template>
  <v-row>
    <dashboard-set-column v-for="(col, colIndex) in dashboard.content" :key="`col.${colIndex}`">
      <dashboard-set-category v-for="(category, categoryIndex) in col.categories"
                              :key="`category.${categoryIndex}`"
                              :category="category">
          <span v-if="!('links' in category)"
                class="text-body-2 text--secondary font-italic">{{ $t('dashboard.customizedStartpage.noLink') }}
          </span>
        <DashboardSetLink v-for="(link, linkIndex) in category.links"
                          :key="`link.${linkIndex}`"
                          :link="link"/>
      </dashboard-set-category>
    </dashboard-set-column>
  </v-row>
</template>

<script>
import { show } from '@/api/endpoints/dashboard';
import Dashboard from '@/application/models/dashboard';
import DashboardSetColumn from '@/components/dashboard/DashboardColumn.vue';
import DashboardSetCategory from '@/components/dashboard/DashboardSetCategory.vue';
import DashboardSetLink from '@/components/dashboard/DashboardSetLink.vue';
import eventBus from '@/eventBus.js';

export default {
  name: 'CustomizedStartpage',
  components: {
    DashboardSetLink,
    DashboardSetCategory,
    DashboardSetColumn,
  },
  data() {
    return {
      dashboard: new Dashboard(),
    };
  },
  props: {
    dashboardId: {
      type: Number,
      required: true,
    },
  },
  watch: {
    dashboardId: {
      async handler() {
        const { data: { data } } = await show(this.dashboardId);
        this.dashboard = new Dashboard();
        this.dashboard = this.dashboard.mapResponse(data);

        eventBus.$emit('appTitle', this.dashboard.name);
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    eventBus.$emit('appTitle', '');
  },
};
</script>
